import { createSlice } from '@reduxjs/toolkit';
// utils
import { COUNTRIES, LANGUAGES, CURRENCIES, KYC_STATUSES } from '../../utils/apiUrl';
import { authApi } from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  countries: [],
  languages: [],
  currencies: [],
};

const slice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET COUNTRIES
    getCountriesSuccess(state, action) {
      state.isLoading = false;
      state.countries = action.payload;
    },

    // GET LANGUAGES
    getLanguagesSuccess(state, action) {
      state.isLoading = false;
      state.languages = action.payload;
    },

    // GET CURRENCIES
    getCurrenciesSuccess(state, action) {
      state.isLoading = false;
      state.currencies = action.payload;
    },

    // GET KYC_STATUSES
    getKycStatusesSuccess(state, action) {
      state.isLoading = false;
      state.kycStatuses = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getCountries() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await authApi.get(COUNTRIES);
      dispatch(slice.actions.getCountriesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLanguages() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await authApi.get(LANGUAGES);
      dispatch(slice.actions.getLanguagesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCurrencies() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await authApi.get(CURRENCIES);
      dispatch(slice.actions.getCurrenciesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getKycStatuses() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await authApi.get(KYC_STATUSES);
      dispatch(slice.actions.getKycStatusesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

// export function createEvent(newEvent) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await authApi.post('/api/calendar/events/new', newEvent);
//       dispatch(slice.actions.createEventSuccess(response.data.event));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

// export function updateEvent(eventId, updateEvent) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await authApi.post('/api/calendar/events/update', {
//         eventId,
//         updateEvent,
//       });
//       dispatch(slice.actions.updateEventSuccess(response.data.event));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

// export function deleteEvent(eventId) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       await authApi.post('/api/calendar/events/delete', { eventId });
//       dispatch(slice.actions.deleteEventSuccess({ eventId }));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

// export function selectRange(start, end) {
//   return async () => {
//     dispatch(
//       slice.actions.selectRange({
//         start: start.getTime(),
//         end: end.getTime(),
//       })
//     );
//   };
// }

import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import HeaderCard from '../../../../components/cards/headerCard/headerCard';

const DepositsHeaders = ({ netEarning, totalData, isProfitLoss }) => {
  return (
    <Box sx={{ paddingInline: '24px', paddingBottom: '24px' }}>
      <Grid container justifyContent={'end'} spacing={3}>
        <Grid item xs={12} md={4} lg={3}>
          <HeaderCard
            title="Net Earning"
            total={netEarning || 0}
            sx={{ backgroundColor: isProfitLoss === 1 || isProfitLoss === 0 ? 'green' : 'red' }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ marginTop: 0 }}>
        <Grid item xs={12} md={4} lg={3}>
          <HeaderCard
            title="Total Number of Transactions"
            notAmount
            total={totalData.total_transactions || 0}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <HeaderCard title="Transaction Value" total={totalData.transaction_value || 0} sx={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <HeaderCard title="Cashback Offered" total={totalData.cashback || 0} sx={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <HeaderCard title="STB Transaction Fee" total={totalData.stb_fee || 0} sx={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <HeaderCard title="STB Earning" total={totalData.stb_earning || 0} sx={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <HeaderCard title="STB Loss" total={totalData.stb_expense || 0} sx={{ width: '100%' }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DepositsHeaders;

import { useNavigate } from 'react-router';
import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { fDate } from '../../../../../utils/formatTime';
import Label from '../../../../../components/Label';
import { PATH_ACCOUNT } from '../../../../../routes/paths';

const RetailTransactionTableRow = ({ row }) => {
  const {
    id,
    reference_number,
    created_date,
    payment_mode,
    sender,
    receiver,
    transaction_type,
    send_amount,
    other_fee,
    stb_fee,
    cashback,
    discount,
    receipt_amount,
    stb_earning,
    stb_expense,
    status,
  } = row;

  const navigate = useNavigate();

  return (
    <TableRow key={id}>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
        {fDate(created_date)}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {reference_number}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {payment_mode}
      </TableCell>
      <TableCell
        align="left"
        sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
        onClick={() => navigate(PATH_ACCOUNT.retailUsers.profile(sender?.wallet_account_id, 'transactions')) }
      >
        {sender?.name}
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
        {receiver?.name}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {transaction_type}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {send_amount}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {other_fee}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {cashback || 0}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {discount || 0}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {stb_fee}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {receipt_amount}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {stb_earning}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {stb_expense}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        <Label color={(status === 'processed' && 'success') || 'error'} sx={{ textTransform: 'capitalize' }}>
          {status}
        </Label>
      </TableCell>
    </TableRow>
  );
};

export default RetailTransactionTableRow;

// routes
import {
  PATH_ACCOUNT,
  PATH_TRANSACTION,
  PATH_XRPL_WALLET,
  PATH_MERCHANTS,
  PATH_SUPPORT,
  PATH_CONFIGURATION,
  PATH_AUDIT_TRAIL,
  PATH_NOTIFICATION,
  PATH_REPORTING,
} from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  wallet: getIcon('ic_wallet'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  config: getIcon('ic_config'),
  audit: getIcon('ic_audit'),
  notification: getIcon('ic_notification'),
  reporting: getIcon('ic_reporting'),
};

const navConfig = [
  {
    items: [
      {
        title: 'Accounts',
        path: PATH_ACCOUNT.root,
        icon: ICONS.user,
        children: [
          { title: 'Retail Users', path: PATH_ACCOUNT.retailUsers.root },
          { title: 'Merchant Users', path: PATH_ACCOUNT.merchantUsers.root },
          { title: 'Retail KYC List', path: PATH_ACCOUNT.retailKycTable.root },
          { title: 'Merchant KYB List', path: PATH_ACCOUNT.merchantKybTable.root },
        ],
      },
      {
        title: 'Transactions',
        path: PATH_TRANSACTION.root,
        icon: ICONS.invoice,
        children: [
          // { title: 'bitgo transactions', path: PATH_TRANSACTION.bitgo.root },
          {
            title: 'wallet transactions',
            path: PATH_TRANSACTION.wallet.root,
            children: [
              { title: 'Retail User', path: PATH_TRANSACTION.wallet.retailUser },
              { title: 'Merchant User', path: PATH_TRANSACTION.wallet.merchantUser },
            ],
          },
          {
            title: 'Apaylo Deposit',
            path: PATH_TRANSACTION.deposit.root,
            children: [{ title: 'Retail User', path: PATH_TRANSACTION.deposit.retailUser }],
          },
          {
            title: 'Apaylo Withdraw',
            path: PATH_TRANSACTION.withdraw.root,
            children: [
              { title: 'Retail User', path: PATH_TRANSACTION.withdraw.retailUser },
              { title: 'Merchant User', path: PATH_TRANSACTION.withdraw.merchantUser },
            ],
          },
          // { title: 'Funding Queue', path: PATH_TRANSACTION.fundingQueue.root },
          // { title: 'EFT Accounts', path: PATH_TRANSACTION.eftAccounts.root },
        ],
      },
      // {
      //   title: 'Wallets',
      //   path: PATH_XRPL_WALLET.root,
      //   icon: ICONS.wallet,
      //   children: [
      //     { title: 'wallets', path: PATH_XRPL_WALLET.wallets.root },
      //     { title: 'central wallets', path: PATH_XRPL_WALLET.centralWallets.root },
      //   ],
      // },
      {
        title: 'Configuration',
        path: PATH_CONFIGURATION.root,
        icon: ICONS.config,
        children: [
          {
            title: 'Offers',
            path: PATH_CONFIGURATION.offers.root,
          },
          {
            title: 'Admin Emails',
            path: PATH_CONFIGURATION.adminEmails.root,
          },
          {
            title: 'STB Staff Management',
            path: PATH_CONFIGURATION.stbStaff.root,
          },
          {
            title: 'STB Fees',
            path: PATH_CONFIGURATION.stbFees.root,
          },
          // {
          //   title: 'Country Supported Coins',
          //   path: PATH_CONFIGURATION.countrySupportedCoin.root,
          // },
          // {
          //   title: 'Derogatory Words',
          //   path: PATH_CONFIGURATION.derogatoryWords.root,
          // },
          // {
          //   title: 'Coins',
          //   path: PATH_CONFIGURATION.coins.root,
          // },
          {
            title: 'App Configuration',
            path: PATH_CONFIGURATION.appConfiguration.root,
          },
          {
            title: 'Demographics',
            path: PATH_CONFIGURATION.demographics.root,
            children: [
              // { title: 'Countries', path: PATH_CONFIGURATION.demographics.countries.root },
              // { title: 'Provinces', path: PATH_CONFIGURATION.demographics.provinces.root },
              // { title: 'Currencies', path: PATH_CONFIGURATION.demographics.currencies.root },
              { title: 'Languages', path: PATH_CONFIGURATION.demographics.languages.root },
              // { title: 'Exchange Rates', path: PATH_CONFIGURATION.demographics.exchangeRates.root },
            ],
          },
          {
            title: 'App Versions',
            path: PATH_CONFIGURATION.version.root,
          },
          // {
          //   title: 'XRPL Status Code Mapping',
          //   path: PATH_CONFIGURATION.xrplStatusCodes.root,
          // },
          {
            title: 'Accuant Errors',
            path: PATH_CONFIGURATION.accuantError.root,
          },
        ],
      },
      {
        title: 'Merchant',
        path: PATH_MERCHANTS.root,
        icon: ICONS.ecommerce,
        children: [
          { title: 'Business Categories', path: PATH_MERCHANTS.businessCategories.root },
          { title: 'Business Types', path: PATH_MERCHANTS.businessType.root },
          // { title: 'Merchant configs', path: PATH_MERCHANTS.merchantConfig.root },
          { title: 'Merchant roles', path: PATH_MERCHANTS.merchantRoles.root },
          { title: 'Merchants', path: PATH_MERCHANTS.merchants.root },
          // { title: 'Merchant Stores', path: PATH_MERCHANTS.merchantStores.root },
          { title: 'Document Types', path: PATH_MERCHANTS.merchantDocumentTypes.root },
        ],
      },
      {
        title: 'Audit Trails',
        path: PATH_AUDIT_TRAIL.root,
        icon: ICONS.audit,
        children: [{ title: 'Audit Trails', path: PATH_AUDIT_TRAIL.root }],
      },
      {
        title: 'Notifications',
        path: PATH_NOTIFICATION.root,
        icon: ICONS.notification,
        children: [{ title: 'In-App Retail Notifications', path: PATH_NOTIFICATION.version.root }],
      },
      {
        title: 'Reporting',
        path: PATH_REPORTING.root,
        icon: ICONS.reporting,
        // children: [
        //   { title: 'Revenue Reports', path: PATH_REPORTING.revenueReport.root },
        //   { title: 'Revenue Component Break Report', path: PATH_REPORTING.revenueComponentBreakReport.root },
        //   { title: 'P2P Transaction Volume', path: PATH_REPORTING.p2pTransactionVolume.root },
        //   { title: 'P2B Transaction Volume', path: PATH_REPORTING.p2bTransactionVolume.root },
        //   { title: 'Deposit Transaction Volume', path: PATH_REPORTING.depositTransactionVolume.root },
        //   { title: 'Withdraw Volume', path: PATH_REPORTING.withdrawVolume.root },
        //   { title: 'Bill Payments Volume', path: PATH_REPORTING.billPaymentsVolume.root },
        //   { title: 'Balance Diffrence Report', path: PATH_REPORTING.balanceDiffrenceReport.root },
        // ],
        children: [
          { title: 'Master Transactions', path: PATH_REPORTING.masterTransactions.root },
          { title: 'P2P Transactions', path: PATH_REPORTING.p2pTransactionVolume.root },
          { title: 'P2B Transactions', path: PATH_REPORTING.p2bTransactionVolume.root },
          { title: 'Bill Payments', path: PATH_REPORTING.billPaymentsVolume.root },
          { title: 'Deposits', path: PATH_REPORTING.depositTransactionVolume.root },
          { title: 'Withdrawal', path: PATH_REPORTING.withdrawVolume.root },
        ],
      },
      {
        title: 'Support',
        path: PATH_SUPPORT.root,
        icon: ICONS.chat,
        children: [
          // { title: 'Support Agents', path: PATH_SUPPORT.support.agentSupport.root },
          {
            title: 'Merchant Support',
            path: PATH_SUPPORT.support.merchantSupport.supportCategories.root,
            children: [
              { title: 'Support Categories', path: PATH_SUPPORT.support.merchantSupport.supportCategories.root },
              { title: 'Support Tickets', path: PATH_SUPPORT.support.merchantSupport.supportTicket.root },
              { title: 'Merchant FAQ', path: PATH_SUPPORT.support.merchantSupport.merchantFAQ.root },
              { title: 'Priorities', path: PATH_SUPPORT.support.merchantSupport.priority.root },
            ],
          },
          {
            title: 'User Support',
            path: PATH_SUPPORT.support.userSupport.supportTicket.root,
            children: [{ title: 'Support Tickets', path: PATH_SUPPORT.support.userSupport.supportTicket.root }],
          },
        ],
      },
    ],
  },
];

export default navConfig;

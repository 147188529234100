import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import RetailTransactionsData from './retailTransactionReport/retailTransactionsData';
import MerchantTransactionsData from './merchantTransactionReport/merchantTransactionsData';

// Tab Content Panel
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`masterTransaction-tabpanel-${index}`}
      aria-labelledby={`masterTransaction-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `masterTransaction-tab-${index}`,
    'aria-controls': `masterTransaction-tabpanel-${index}`,
  };
}

const MasterTransactions = () => {
  const [activeTab, setActiveTab] = useState(0);

  // Handling Change when tab is changed
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // console.log('table data', tableData);

  return (
    <Page title="Master Transaction Report">
      <HeaderBreadcrumbs
        heading="Master Transaction Report"
        links={[{ name: 'Master Transaction' }, { name: 'List' }]}
      />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Retail" {...a11yProps(0)} />
            <Tab label="Merchant" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={activeTab} index={0}>
          <RetailTransactionsData />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index={1}>
          <MerchantTransactionsData />
        </CustomTabPanel>
      </Box>
    </Page>
  );
};

export default MasterTransactions;

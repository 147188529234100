import React, { useState, useEffect } from 'react';
import { Box, Card, FormControlLabel, Switch, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useSnackbar } from 'notistack';
import Scrollbar from '../../../../components/Scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSkeleton } from '../../../../components/table';
import useTable, { emptyRows } from '../../../../hooks/useTable';
import { adminApi } from '../../../../utils/axios';
import { ADMIN_P2P_TRANSACTION, ADMIN_RETAIL_REPORT } from '../../../../utils/apiUrl';
import { fCustomDate } from '../../../../utils/formatTime';
import P2pTransactionsFilter from './p2pTransactionFilter';
import P2pTransactionHeaders from './p2pTransactionHeaders';
import P2pTransactionTableRow from './p2pTransactionTableRow';

const TABLE_HEAD = [
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'tran_id', label: 'Tran ID', align: 'left' },
  { id: 'from', label: 'From', align: 'left' },
  { id: 'to', label: 'To', align: 'left' },
  { id: 'transaction_value', label: 'Transaction Value', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
];

const P2pTransactionsData = () => {
  // Getting Current Date
  const currentDate = new Date();

  // Getting Date which is 30 Days back from current date
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);

  const { dense, page, order, orderBy, rowsPerPage, setPage, onChangeDense, onChangePage, onChangeRowsPerPage } =
    useTable();

  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState({ count: 0, data: [] });
  const [filterParameters, setFilterParameters] = useState({
    search: '',
    status: 'processed',
    from_date: fCustomDate(new Date(thirtyDaysAgo)),
    to_date: fCustomDate(new Date(currentDate)),
  });
  const [totalData, setTotalData] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getP2pTransaction();
  }, [rowsPerPage, page, filterParameters]);

  // Getting P2P Transaction Data
  const getP2pTransaction = async () => {
    // console.log('filter parameters', filterParameters);
    setIsLoading(true);
    try {
      const response = await adminApi.get(ADMIN_P2P_TRANSACTION, {
        params: {
          offset: rowsPerPage * page,
          limit: rowsPerPage,
          ...filterParameters,
        },
      });
      console.log(response, 'response');
      setIsLoading(false);
      setTableData(response?.data);
      setTotalData({ ...response?.data.totals, total_transactions: response?.data.count });
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar(error?.message, { variant: 'error' });
      setTableData(error);
    }
  };

  // If there is no data
  const isNotFound = tableData?.results?.length === 0;

  // Check Dense Height
  const denseHeight = dense ? 52 : 72;

  return (
    <Card sx={{ margin: '40px 0px' }}>
      {/* P2P Report Filter */}
      <P2pTransactionsFilter
        filterParameters={filterParameters}
        onFilterChange={(val) => {
          setFilterParameters(val);
          setPage(0);
        }}
      />

      {/* P2P Report Headers */}
      {totalData && (
        <P2pTransactionHeaders totalData={totalData} />
      )}

      {/* P2P Report Data Table */}
      <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
        <Scrollbar>
          <Table size={dense ? 'small' : 'medium'}>
            <TableHeadCustom order={order} orderBy={orderBy} headLabel={TABLE_HEAD} rowCount={tableData.count} />
            {isLoading ? (
              <>
                <TableSkeleton />
                <TableSkeleton />
                <TableSkeleton />
                <TableSkeleton />
                <TableSkeleton />
              </>
            ) : (
              <TableBody>
                {tableData?.results?.map((row) => (
                  <P2pTransactionTableRow key={row.id} row={row} />
                ))}
                <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.count)} />
                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            )}
          </Table>
        </Scrollbar>
      </TableContainer>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={tableData.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />

        <FormControlLabel
          control={<Switch checked={dense} onChange={onChangeDense} />}
          label="Dense"
          sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
        />
      </Box>
    </Card>
  );
};

export default P2pTransactionsData;

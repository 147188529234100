import Button from '@mui/material/Button';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Tooltip } from '@mui/material';
import Iconify from '../Iconify';

const ExportExcel = ({ excelData, fileName }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
  const fileExtension = '.xlsx';

  const headerStyle = {
    fill: { fgColor: { rgb: 'FF000000' } }, // Black background
    font: { color: { rgb: 'FFFFFFFF' } } // White text color
};

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    // Get the first row (header row)
    const headerRange = XLSX.utils.decode_range(ws['!ref']);
    const headerRow = headerRange.s.r;

    // Apply the style to each cell in the header row
    for (let col = headerRange.s.c; col <= headerRange.e.c; col += 1) {
        const cellAddress = XLSX.utils.encode_cell({ c: col, r: headerRow });
        if (ws[cellAddress]) {
            ws[cellAddress].s = headerStyle; // Apply the header style
        }
    }

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <Tooltip title="Excel Export">
        <Button
          onClick={() => exportToExcel()}
          variant="contained"
          color="primary"
          endIcon={<Iconify icon={'ph:export'} />}
          style={{ cursor: 'pointer', fontSize: 14 }}
        >
          Export
        </Button>
      </Tooltip>
    </>
  );
};
export default ExportExcel;

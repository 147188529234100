/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { TextField, MenuItem, IconButton, InputAdornment } from '@mui/material';
import Iconify from '../Iconify';
import useSettings from '../../hooks/useSettings';

SelectInput.propTypes = {
  onChange: PropTypes.func,
  optionsRole: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  isClear: PropTypes.func,
  isDisabled: PropTypes.bool,
  ref: PropTypes.bool,
  register: PropTypes.any,
  control: PropTypes.any,
  children: PropTypes.any,
};

export default function SelectInput({
  onChange,
  optionsRole,
  label,
  size,
  name,
  value,
  isClear,
  isDisabled,
  ...other
}) {
  const { themeMode } = useSettings();

  return (
    <>
      <TextField
        {...other}
        select
        fullWidth
        size={size}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        InputLabelProps={{
          style: { color: isDisabled ? '#919EAB' : themeMode === 'dark' ? 'white' : 'black' },
        }}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        InputProps={{
          startAdornment: value && (
            <InputAdornment position="start" onClick={() => isClear(name)}>
              <IconButton>
                <Iconify icon={'majesticons:close-line'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          textTransform: 'capitalize',
        }}
      >
        {optionsRole.map((option) => (
          <MenuItem
            // selected={value === option.value}
            selected
            key={option?.label}
            value={option?.value}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option?.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import P2pTransactionsData from './p2pTransactionData';

const P2pTransactions = () => {
  const [activeTab, setActiveTab] = useState(0);

  // Handling Change when tab is changed
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // console.log('table data', tableData);

  return (
    <Page title="P2P Transaction Report">
      <HeaderBreadcrumbs
        heading="P2P Transaction Report"
        links={[{ name: 'P2P Transaction' }]}
      />
      <Box sx={{ width: '100%' }}>
        <P2pTransactionsData />
      </Box>
    </Page>
  );
};

export default P2pTransactions;

import { join, startsWith, slice } from 'lodash';

export const textCapitalize = (text) => text.toLowerCase().replace(/\b./g, (a) => a.toUpperCase());

export const getFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
};

export const isWebsiteUrl = (url) => url.includes('http');

export const getFileNameFromUrl = (url) => url.split('/').pop();

export const numricRegex = /(?=.*[0-9])/;

export const removeCodeFromPhone = (phone, code) => {
  if (startsWith(phone, code)) {
    return join(slice(phone, code.length), '');
  }
  return phone;
};

// eslint-disable-next-line no-useless-escape
export const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

/* eslint-disable no-restricted-syntax */
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
// form
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, CardHeader, Container, MenuItem, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { RHFTextField, FormProvider } from '../../../../../components/hook-form';
import CustomSelect from '../../../../../components/hook-form/CustomSelectInput';
import { adminApi } from '../../../../../utils/axios';
import {
  ADMIN_COUNTRY,
  ADMIN_CURRENCY,
  MERCHANT_BUSINESS_CATEGORY,
  MERCHANT_BUSINESS_TYPE,
  MERCHANT_LIST,
  MERCHANT_STATUS,
  MERCHANT_STORE,
  MERCHANT_USER_ROLES,
} from '../../../../../utils/apiUrl';
// import MerchantKycKybStatusForm from './merchantKycKybStatusForm';
import RHFPhoneInput from '../../../../../components/hook-form/RHFPhoneInput';
import Page from '../../../../../components/Page';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import useSettings from '../../../../../hooks/useSettings';
import { PATH_ACCOUNT, PATH_MERCHANTS } from '../../../../../routes/paths';

// MerchantBusinessInfo.propTypes = {
//   merchantData: PropTypes.any,
//   kycStatuses: PropTypes.any,
// };

export default function MerchantUserCreateStore() {
  const [isLoading, setIsLoading] = useState(false);
  const [currencyTypeData, setCurrencyTypeData] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const storeState = location.state;

  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();

  const merchantUserCreateStoreSchema = Yup.object().shape({
    name: Yup.string().required('This is required'),
    phone: Yup.string().required('This is required'),
    postal_code: Yup.string().required('This is required'),
    store_id: Yup.string().required('This is required'),
    city: Yup.string().required('This is required'),
    // currency: Yup.string().required('This is required'),
    // status: Yup.string().required('This is required'),
  });

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = () => {
    // Getting Country List
    adminApi
      .get(ADMIN_CURRENCY, {
        params: {
          limit: 1000,
        },
      })
      .then(({ data }) => {
        setCurrencyTypeData(data?.results);
      })
      .catch((error) => {
        console.log('Error: ', error.message);
      });

    // Getting store status
    adminApi
      .get(MERCHANT_STATUS, {
        params: {
          limit: 100,
        },
      })
      .then(({ data }) => {
        setStatusList(data?.data);
      })
      .catch((error) => {
        console.log('Error: ', error.message);
      });
  };

  const defaultValues = useMemo(
    () => ({
      name: storeState?.currentStore?.name || '',
      phone: storeState?.currentStore?.phone || '',
      postal_code: storeState?.currentStore?.postal_code || '',
      store_id: storeState?.currentStore?.store_id || '',
      city: storeState?.currentStore?.city || '',
      // currency: storeState?.currentStore?.currency?.id || '',
      // status: storeState?.currentStore?.status || '',
      address: storeState?.currentStore?.address || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(merchantUserCreateStoreSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleClear = (name) => {
    setValue(name, '');
  };

  const values = watch();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      if (storeState?.isEdit) {
        adminApi
          .patch(`${MERCHANT_STORE}${storeState?.currentStore?.id}/`, data)
          .then(() => {
            setIsLoading(false);
            enqueueSnackbar(!storeState?.isEdit ? 'Create success!' : 'Update success!');
            navigate(PATH_MERCHANTS.merchants.profile(storeState?.merchantId, 'stores'));
          })
          .catch((error) => {
            if (typeof error === 'object') {
              setIsLoading(false);
              for (const [key, value] of Object.entries(error)) {
                enqueueSnackbar(`${key}: ${value}`, { variant: 'error' });
              }
            } else {
              setIsLoading(false);
              enqueueSnackbar('Something went wrong', { variant: 'error' });
            }
          });
      } else {
        adminApi
          .post(`${MERCHANT_STORE}?merchant=${storeState.merchantId}`, data)
          .then(() => {
            reset();
            setIsLoading(false);
            enqueueSnackbar(!storeState?.isEdit ? 'Create success!' : 'Update success!');
            navigate(PATH_MERCHANTS.merchants.profile(storeState?.merchantId, 'stores'));
          })
          .catch((error) => {
            setIsLoading(false);
            enqueueSnackbar('Something went wrong', { variant: 'error' });
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log('currency type', { storeState });

  return (
    <Page title={storeState?.isEdit ? 'Edit Store' : 'Add New Store'}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={storeState?.isEdit ? 'Edit Store' : 'Add New Store'}
          links={[
            { name: 'Store' },
            // { name: 'User', href: PATH_DASHBOARD.account.users },
            { name: storeState?.isEdit ? 'Edit' : 'Add' },
          ]}
        />
        <Card sx={{ maxWidth: '80%', margin: '0 auto' }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                p: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="name" label="Name" />
              <RHFPhoneInput name="phone" label="Phone Number" />
              <RHFTextField name="postal_code" label="Postal Code" />
              <RHFTextField name="store_id" label="Store ID" />
              <RHFTextField name="city" label="City" />
              {/* <CustomSelect
                name="currency"
                label="Currency"
                placeholder="Currency"
                isClear={handleClear}
                isDisabled={!(currencyTypeData && currencyTypeData.length > 0)}
              >
                {currencyTypeData?.map((option) => (
                  <MenuItem
                    selected
                    key={option?.code}
                    value={option?.id}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option?.code}
                  </MenuItem>
                ))}
              </CustomSelect>
              <CustomSelect
                name="status"
                label="Status"
                placeholder="Status"
                isClear={handleClear}
                isDisabled={!(statusList && statusList.length > 0)}
              >
                {statusList?.map((option) => (
                  <MenuItem
                    // selected={value === option.value}
                    selected
                    key={option?.name}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option?.name}
                  </MenuItem>
                ))}
              </CustomSelect> */}
            </Box>
            <Box sx={{ p: 3 }}>
              <RHFTextField name="address" label="Address" multiline rows={2} maxRows={8} />
            </Box>
            <Stack spacing={3} alignItems="flex-end" sx={{ p: 3, marginLeft: 'auto' }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <LoadingButton type="submit" variant="contained" loading={isLoading}>
                  {storeState?.isEdit ? 'Update Store' : 'Add Merchant Store'}
                </LoadingButton>
              </Box>
            </Stack>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
}

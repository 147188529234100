import { Box, Button, Grid, InputAdornment, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import Iconify from '../../../../components/Iconify';
import SelectInput from '../../../../components/hook-form/SelectInput';
import CustomDateRangePicker from '../../../../components/input/CustomDatePicker';
import { fCustomDate, fDate } from '../../../../utils/formatTime';
import {
  ADMIN_P2B_TRANSACTION,
  ADMIN_PAYMENT_MODE,
  ADMIN_TRANSACTION_STATUS,
  ADMIN_TRANSACTION_TYPES,
} from '../../../../utils/apiUrl';
import { adminApi } from '../../../../utils/axios';
import ExportExcel from '../../../../components/exportExcel/exportExcel';
import { fCurrency } from '../../../../utils/formatNumber';

const P2bTransactionsFilter = ({ filterParameters, onFilterChange }) => {
  const currentDate = new Date();

  // Getting Date which is 30 Days back from current date
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);

  const [showFilter, setShowFilter] = useState(false);
  const [transactionStatusData, setTransactionStatusData] = useState([]);
  const [filterData, setFilterData] = useState(filterParameters);
  const [exportData, setExportData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => getP2bTransaction(), [filterParameters]);

  const getP2bTransaction = async () => {
    try {
      const response = await adminApi.get(ADMIN_P2B_TRANSACTION, {
        params: {
          limit: 1000,
          ...filterParameters,
        },
      });
      console.log('tran data', response?.data);
      setExportData(
        response?.data?.results?.map((item) => ({
          Date: fDate(item?.created_date),
          'Tran ID': item?.reference_number,
          From: item?.sender?.name,
          To: item?.receiver?.name,
          'Transaction Value': fCurrency(item?.send_amount),
          Cashback: fCurrency(item?.cashback),
          'STB Transaction Fee': fCurrency(item?.stb_fee),
          'Merchant Reciept': fCurrency(item?.receipt_amount),
          'STB Earning': fCurrency(item?.stb_earning),
          'STB Loss': fCurrency(item?.stb_expense),
          Status: item?.status,
        }))
      );
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => onFilterChange(filterData), 800);

    return () => clearTimeout(timer);
  }, [filterData]);

  useEffect(() => {
    getTransactionStatusData();
  }, []);

  // Getting Transaction Status data
  const getTransactionStatusData = async () => {
    try {
      const response = await adminApi.get(ADMIN_TRANSACTION_STATUS);
      // console.log(response, 'response');
      setTransactionStatusData(response?.data.data.map((data) => ({ label: data.name, value: data.value })));
    } catch (error) {
      console.log(error);
    }
  };

  // console.log('tran data', { transactionTypeData, paymentModeData, transactionStatusData });

  return (
    <Box sx={{ margin: '20px 24px' }}>
      <Grid container spacing={3} justifyContent={'space-between'}>
        <Grid item xs={12} md={4} lg={2}>
          <Button
            sx={{ paddingBlock: '15px', width: '100%' }}
            variant="contained"
            endIcon={<Iconify icon={'material-symbols:filter-list-rounded'} />}
            onClick={() => setShowFilter((prev) => !prev)}
          >
            Filters
          </Button>
        </Grid>
        <Grid item xs={12} md={4} lg={2} sx={{ textAlign: 'right' }}>
          <ExportExcel excelData={exportData} fileName="P2B Transaction" />
        </Grid>
      </Grid>
      {showFilter && (
        <>
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={12} md={6} lg={6}>
              <CustomDateRangePicker
                label1="Start Date"
                label2="End Date"
                name1="from_date"
                name2="to_date"
                onChange={(e, name) => setFilterData((prev) => ({ ...prev, [name]: e === null ? '' : fCustomDate(e) }))}
                val1={filterData?.from_date}
                val2={filterData?.to_date}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ textAlign: 'right' }}>
              <Button
                sx={{ paddingBlock: '15px' }}
                variant="contained"
                onClick={() =>
                  setFilterData({
                    search: '',
                    status: 'processed',
                    from_date: fCustomDate(new Date(thirtyDaysAgo)),
                    to_date: fCustomDate(new Date(currentDate)),
                  })
                }
              >
                Reset Filters
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                onChange={(event) => setFilterData((prev) => ({ ...prev, search: event.target.value }))}
                placeholder="Search by Transaction id or User Name/Email/Phone"
                value={filterData.search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <SelectInput
                label="Status"
                optionsRole={transactionStatusData}
                onChange={(event) => setFilterData((prev) => ({ ...prev, status: event.target.value }))}
                value={filterData.status}
                name="status"
                isClear={() => setFilterData((prev) => ({ ...prev, status: '' }))}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default P2bTransactionsFilter;

/* eslint-disable no-unused-vars */
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import { authApi } from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isTwoFactorAuthenticationVerififed: 'false',
  isTwoFactorAuthentication: 'disabled',
  twoFactorAuthenticationStatus: 'pending',
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    console.log('yeeeeee chaaaalaaaa');
    const {
      isAuthenticated,
      user,
      isTwoFactorAuthentication,
      twoFactorAuthenticationStatus,
      isTwoFactorAuthenticationVerififed,
    } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isTwoFactorAuthentication,
      twoFactorAuthenticationStatus,
      isTwoFactorAuthenticationVerififed,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isTwoFactorAuthentication: user.two_factor_authentication,
      twoFactorAuthenticationStatus: user.two_factor_authentication_status,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    isTwoFactorAuthentication: 'disabled',
    twoFactorAuthenticationStatus: 'pending',
    isTwoFactorAuthenticationVerififed: 'false',
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  // console.log('in Auth Context');
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        const userinfo = JSON.parse(localStorage.getItem('user'));

        // console.log('user info', userinfo);

        if (accessToken && refreshToken) {
          setSession(accessToken, refreshToken);

          // const response = await authApi.get('/api/account/my-account');
          // const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              isTwoFactorAuthentication: userinfo.two_factor_authentication,
              twoFactorAuthenticationStatus: userinfo.two_factor_authentication_status,
              isTwoFactorAuthenticationVerififed: localStorage.getItem('isTwoFactorAuthenticationVerififed'),
              user: userinfo,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              isTwoFactorAuthentication: 'disabled',
              twoFactorAuthenticationStatus: 'pending',
              isTwoFactorAuthenticationVerififed: 'false',
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            isTwoFactorAuthentication: 'disabled',
            twoFactorAuthenticationStatus: 'pending',
            isTwoFactorAuthenticationVerififed: 'false',
            user: null,
          },
        });
      }
    };
    console.log('in Auth Context');
    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await authApi.post('/v1/user/admin/login/', {
      email,
      password,
    });

    console.log('cewcr3x424x34x', response.data);
    const { access, refresh } = response.data.data;
    console.log('tokensss', { access, refresh });
    localStorage.setItem('user', JSON.stringify(response.data.data));
    localStorage.setItem('isTwoFactorAuthenticationVerififed', false);

    setSession(access, refresh);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: response.data.data,
      },
    });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await authApi.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null, null);
    dispatch({ type: 'LOGOUT' });
  };

  console.log('current user state', state);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

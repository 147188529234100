import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, Typography } from '@mui/material';
import useSettings from '../../hooks/useSettings';

// ----------------------------------------------------------------------

RHFPhoneInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default function RHFPhoneInput({ name, label, ...other }) {
  const { themeMode } = useSettings();
  const { control } = useFormContext();
  console.log(themeMode, 'themeMode');

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box sx={{ width: '100%', position: 'relative' }}>
          <Typography
            sx={{
              position: 'absolute',
              zIndex: 99,
              backgroundColor: themeMode === 'dark' ? '#212B36' : '#fff',
              color: themeMode === 'dark' ? '#fff' : '#000',
              fontSize: '0.75rem',
              left: '10px',
              top: '-13%',
              padding: '0px 4px 3px',
            }}
          >
            {label || 'Mobile No.'}
          </Typography>

          <PhoneInput
            {...field}
            // value={value}
            country={'us'}
            inputStyle={{
              width: '100%',
              height: '56px',
              borderRadius: '8px',
              backgroundColor: themeMode === 'dark' ? '#212B36' : '#fff',
              color: themeMode === 'dark' ? '#fff' : '#000',
              borderColor: themeMode === '#606060' ? 'grey' : '#919EAB',
            }}
            dropdownStyle={{ color: '#000' }}
            buttonStyle={{
              borderRadius: '8px 2px 2px 8px',
              backgroundColor: themeMode === 'dark' ? '#212B36' : '#fff',
              borderRight: 0,
              borderColor: themeMode === '#606060' ? 'grey' : '#919EAB',
            }}
            enableSearch
            specialLabel="Phone No."
            error={!!error}
            helperText={error?.message}
            {...other}
          />
          {error?.message && (
            <Typography variant="caption" color={'error'}>
              {error?.message}
            </Typography>
          )}
        </Box>
        // <TextField {...field} fullWidth error={!!error} helperText={error?.message} {...other} />
      )}
    />
  );
}

import { useNavigate } from 'react-router';
import { TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import _ from 'lodash';
import { fDate, fDateTime } from '../../../../utils/formatTime';
import Iconify from '../../../../components/Iconify';
import { PATH_ACCOUNT } from '../../../../routes/paths';
import Label from '../../../../components/Label';

const MerchantKybListTableRow = ({ row }) => {
  const { id, application_id, created_date, modified_date, merchant, status } = row;

  const navigate = useNavigate();

  return (
    <TableRow key={id}>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
        {fDateTime(created_date)}
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
        {id}
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
        {merchant?.name}
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
        {merchant?.user?.email}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        <Label color={(status === 'complete' && 'success') || 'error'} sx={{ textTransform: 'capitalize' }}>
          {status}
        </Label>
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
        {fDate(modified_date)}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        <Typography
          sx={{ color: '#FFBE00', textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => navigate(PATH_ACCOUNT.merchantUsers.profile(merchant?.user?.id, 'merchant-info'))}
        >
          View
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default MerchantKybListTableRow;

import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const {
    isAuthenticated,
    isInitialized,
    isTwoFactorAuthentication,
    twoFactorAuthenticationStatus,
    isTwoFactorAuthenticationVerififed,
  } = useAuth();

  // console.log('auth guard is 2fa', { twoFactorAuthenticationStatus, isAuthenticated });

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);
  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (isAuthenticated && isTwoFactorAuthentication === 'enabled' && isTwoFactorAuthenticationVerififed === 'false') {
    // return <Navigate to={PATH_AUTH.setup2fa} />;
    if (twoFactorAuthenticationStatus === 'pending') {
      return <Navigate to={PATH_AUTH.setup2fa} />;
    }
    return <Navigate to={PATH_AUTH.verify2fa} />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}

import axios from 'axios';
// config
import { HOST_API } from '../config';
import { REFRESH_TOKEN_API } from './apiUrl';
import { setSession } from './jwt';

// ----------------------------------------------------------------------

let isRefreshing = false;
let refreshPromise = null;

export const authApi = axios.create({
  baseURL: HOST_API,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

authApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export const adminApi = axios.create({
  baseURL: HOST_API,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

adminApi.interceptors.request.use(
  (options) => {
    options.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    return options;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

adminApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log('errorinaxios', error)
    const originalRequest = error.config;
    console.log(
      'Variabes====>',
      isRefreshing,
    );

    console.log('a', refreshPromise)

    console.log('b', originalRequest._retry)
    
    console.log('c', error.config)

    // Check if the error is due to an expired token
    if (error.response?.status === 401 && !originalRequest?._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        originalRequest._retry = true;

        // Use the existing refreshPromise if available
        if (!refreshPromise) {
          refreshPromise = getRefreshToken();
        }

        try {
          const newAccessToken = await refreshPromise;
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return adminApi(originalRequest);
        } catch (refreshError) {
          setSession(null, null)
          console.log('acacdcsdcsdc', refreshError)
          // Reset the refreshPromise to allow subsequent attempts
          refreshPromise = null;

          return Promise.reject(refreshError);
        } finally {
          originalRequest._retry = false;
          isRefreshing = false;
          refreshPromise = null;
        }
      } else {
        // If another request is already refreshing the token, wait for that promise to resolve
        return refreshPromise.then(newAccessToken => {
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

          // Retry the original request
          return adminApi(originalRequest);
        });
      }
    }

    console.log(error?.response || error);
    return Promise.reject(error);
  }
);

async function getRefreshToken() {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await authApi.post(REFRESH_TOKEN_API, {
      refresh: refreshToken,
    });
    const {access, refresh} = response.data;
    // GlobalStore.STORE.token = access;
    // GlobalStore.STORE.refresh = refresh;
    // localStorage.setItem("accessToken", access);
    //   localStorage.setItem("refreshToken", refresh);
      setSession(access, refresh)
    return access;
  } catch (e) {
    setSession(null, null)
    isRefreshing = false;
    return Promise.reject(e);
  }
}

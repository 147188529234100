import { useNavigate } from 'react-router';
import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { fDate } from '../../../../../utils/formatTime';
import Label from '../../../../../components/Label';
import { PATH_ACCOUNT } from '../../../../../routes/paths';
import { fCurrency } from '../../../../../utils/formatNumber';

const MerchantTransactionTableRow = ({ row }) => {
  const {
    id,
    reference_number,
    created_date,
    payment_mode,
    sender,
    transaction_type,
    send_amount,
    other_fee,
    stb_fee,
    stb_earning,
    stb_expense,
    status,
    cashback,
    receipt_amount,
    receiver,
  } = row;

  const navigate = useNavigate();

  return (
    <TableRow key={id}>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
        {fDate(created_date)}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {reference_number}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {payment_mode}
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
        {sender?.name}
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
        {receiver?.name}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {transaction_type}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {fCurrency(send_amount)}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {fCurrency(receipt_amount)}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {fCurrency(cashback)}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {fCurrency(other_fee)}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {fCurrency(stb_fee)}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {fCurrency(stb_earning)}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {fCurrency(stb_expense)}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        <Label color={(status === 'processed' && 'success') || 'error'} sx={{ textTransform: 'capitalize' }}>
          {status}
        </Label>
      </TableCell>
    </TableRow>
  );
};

export default MerchantTransactionTableRow;
